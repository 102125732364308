















































import { Psd2CertificateDto } from '@portals/shared/admin/Psd2CertificateDto';
import { format } from 'date-fns';
import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';

export default Vue.extend({
  name: 'psd2-certificate-card',
  components: { DnbIcon },
  filters: {
    formatDate(date: string) {
      return format(date, 'DD-MM-YYYY');
    },
    formatRoles(role: string, roles: string[], index: number) {
      let newRole;

      switch (role) {
        case 'AISP':
          newRole = 'Account information';
          break;
        case 'PISP':
          newRole = 'Payment initiation';
          break;
        case 'PIISP':
          newRole = 'Payment instrument issuing';
          break;
      }
      return index === roles.length - 1 ? newRole : `${newRole},`;
    },
  },
  props: {
    certificate: {
      type: Object as () => Psd2CertificateDto,
      required: true,
    },
  },
  computed: {
    getClasses(): string[] {
      return this.certificate.deleted
        ? [this.$style.card, this.$style.cardOpacity]
        : [this.$style.card];
    },
  },
});
