

























































































type Data = {
  userDetails: UserDetailsDto | undefined;
  loading: boolean;
};
import { AppDto } from '@portals/shared/admin/AppDto';
import { OrganizationDto } from '@portals/shared/admin/OrganizationDto';
import { Psd2CertificateDto } from '@portals/shared/admin/Psd2CertificateDto';
import { TeamDto } from '@portals/shared/admin/TeamDto';
import { UserDetailsDto, UserDto } from '@portals/shared/admin/UserDto';
import { format } from 'date-fns';
import Vue from 'vue';

import AdminSection from '@/components/AdminSection.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DnbButton from '@/components/DnbButton.vue';
import Loader from '@/components/Loader.vue';
import Psd2CertificateCard from '@/components/Psd2CertificateCard.vue';
import { fetchUser } from '@/service/userService';

import { Breadcrumb } from '../../models/Breadcrumb';

export default Vue.extend({
  name: 'view-user',
  components: {
    Loader,
    AdminSection,
    DnbButton,
    Psd2CertificateCard,
    Breadcrumbs,
  },
  filters: {
    formatDate(date: Date) {
      return format(date, 'DD.MM.YYYY');
    },
    formatRoles(roles: string[]) {
      return roles.join(', ');
    },
  },
  data(): Data {
    return {
      userDetails: undefined,
      loading: false,
    };
  },
  computed: {
    user(): UserDto | undefined {
      return this.userDetails ? this.userDetails.user : undefined;
    },
    fullName(): string {
      return this.user
        ? `${this.user.firstName} ${this.user.lastName}`
        : 'User';
    },
    organization(): OrganizationDto | undefined {
      return this.userDetails && this.userDetails.organization
        ? this.userDetails.organization
        : undefined;
    },
    apps(): AppDto[] | undefined {
      return this.userDetails && this.userDetails.apps
        ? this.userDetails.apps
        : undefined;
    },
    liveApps(): AppDto[] | undefined {
      return this.apps
        ? this.apps.filter((app) => app.liveMode.access)
        : undefined;
    },
    sandboxApps(): AppDto[] | undefined {
      return this.apps
        ? this.apps.filter((app) => !app.liveMode.access)
        : undefined;
    },
    teams(): TeamDto[] | undefined {
      return this.userDetails && this.userDetails.teams
        ? this.userDetails.teams
        : undefined;
    },
    certificates(): Psd2CertificateDto[] | undefined {
      return this.userDetails && this.userDetails.certificates
        ? this.userDetails.certificates
        : undefined;
    },
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          name: 'Users',
          path: '/users',
        },
        {
          name: this.fullName,
        },
      ];
    },
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    async loadUser() {
      this.loading = true;
      const userId = this.$route.params.id;
      this.userDetails = await fetchUser(userId);
      this.loading = false;
    },
    goToTeam(teamId: string) {
      this.$router.push(`/teams/${teamId}`);
    },
  },
});
