import {
  UserDetailsDto,
  UserWithCertificateStatusDto,
} from '@portals/shared/admin/UserDto';

import { request } from './request';

export async function fetchUsers(): Promise<UserWithCertificateStatusDto[]> {
  const response = await request<UserWithCertificateStatusDto[]>({
    url: 'users',
  });
  return response.data;
}

export async function fetchUser(userId: string): Promise<UserDetailsDto> {
  const response = await request<UserDetailsDto>({
    url: `users/${userId}`,
    method: 'GET',
  });
  return response.data;
}
